import React, {Fragment, useEffect, useState} from 'react';
import {Col, Divider, Row} from "antd";
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {useHistory} from "react-router-dom";
import {useAuthDispatch, useAuthState} from "../../context";
import {UploadFinish} from "../../components/modal/UploadFinish";
import {FormInputValue} from "../../components/form/FormInputValue";
import {FormInputPasswordValue} from "../../components/form/FormInputPasswordValue";
import {PageConstant} from "../../constants/PageConstant";
import {getProfile} from "../../api/axiosAPIs";
import _ from "lodash";
import {updateProfile} from "../../helper/utils";
import {FormTextAreaValue} from "../../components/form/FormTextAreaValue";


function TeacherProfile(props) {
  let history = useHistory();
  const dispatch = useAuthDispatch()
  const {loading, profile} = useAuthState();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const items = [
    {to: '', label: '教員情報変更'},
  ]

  const onChangeMainInfo = (e) => {
    e.preventDefault()
    history.push(PageConstant.TEACHER_PROFILE_MAIN_INFO)
  }

  const onChangeEmail = (e) => {
    e.preventDefault()
    history.push(PageConstant.TEACHER_PROFILE_EMAIL)
  }

  const onChangePassword = (e) => {
    e.preventDefault()
    history.push(PageConstant.TEACHER_PROFILE_PASSWORD)
  }

  useEffect(() => {
    getProfile().then(response => {
      if (!_.isEmpty(response.data)) {
        if (response.data.data) {
          updateProfile(dispatch, response.data.data, profile.remember)
        }
      }
    })
  }, [])

  return (
    <Fragment>
      <div className={"px-32 pb-12 bg-blue-light"}>
        <Breadcrumb items={items}/>
        <div className={"rounded-xl bg-white overflow-hidden"}>
          <div className={"flex items-center justify-center py-4 bg-profile-yellow"}>
            <span className={"ml-4 text-black text-lg font-bold"}>教員情報変更</span>
          </div>
          <div className={"px-32 py-8"}>
            <Row style={{alignItems: "center"}}>
              <Col span={20}>
                <FormInputValue
                  label={props.intl.formatMessage({id: 'form.item.teacher.name'})}
                  value={profile.name}
                  intl={props.intl}
                  required={false}
                />
                <div className={"py-2"}/>
                {/*担当科目*/}
                <FormTextAreaValue
                  label={props.intl.formatMessage({id: 'form.item.teacher.subject'})}
                  value={profile.teacher ? profile.teacher.subject_name : ''}
                  intl={props.intl}
                  required={false}
                />
                {/*<div className={"py-2"}/>*/}
                {/*担当クラス*/}
                {/*<FormInputValue*/}
                {/*  label={props.intl.formatMessage({id: 'form.item.teacher.class'})}*/}
                {/*  value={profile.teacher ? profile.teacher.class_name : ''}*/}
                {/*  intl={props.intl}*/}
                {/*  required={false}*/}
                {/*/>*/}
              </Col>
              <Col span={4}>
                <div className={"flex items-center justify-center"}>
                  <button
                    className={"flex items-center justify-center bg-blue btn-primary-semi-round h-50px text-white text-base"}
                    style={{width: 220}}
                    onClick={onChangeMainInfo}
                  >
                    <span>変更</span>
                  </button>
                </div>
              </Col>
            </Row>
            <Divider/>
            <Row>
              <Col span={20}>
                <FormInputValue
                  label={"メールアドレス"}
                  value={profile.email}
                  intl={props.intl}
                  required={false}
                />
              </Col>
              <Col span={4}>
                <div className={"flex items-center justify-center"}>
                  <button
                    className={"flex items-center justify-center bg-blue btn-primary-semi-round h-50px text-white text-base"}
                    style={{width: 220}}
                    onClick={onChangeEmail}
                  >
                    <span>変更</span>
                  </button>
                </div>
              </Col>
            </Row>
            <Divider/>
            <Row>
              <Col span={20}>
                <FormInputPasswordValue
                  label={"パスワード"}
                  value={profile.email}
                  intl={props.intl}
                  required={false}
                />
              </Col>
              <Col span={4}>
                <div className={"flex items-center justify-center"}>
                  <button
                    className={"flex items-center justify-center bg-blue btn-primary-semi-round h-50px text-white text-base"}
                    style={{width: 220}}
                    onClick={onChangePassword}
                  >
                    <span>変更</span>
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <UploadFinish
        isModalVisible={isModalVisible}
        handleCancel={() => setIsModalVisible(false)}
      />
    </Fragment>
  )
}

export default injectIntl(TeacherProfile)
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {Link, useHistory, useLocation} from "react-router-dom";
import {useAuthState} from "../../context";
import {PageConstant} from "../../constants/PageConstant";
import {Bookmark} from "../../components/bookmark/Bookmark";
import {LessonItem} from "../../components/program/LessonItem";
import {ProgramShare} from "../../components/modal/ProgramShare";
import {addSharedProgramUrl, getLessonList} from "../../api/axiosAPIs";
import _ from "lodash";
import {useReactToPrint} from 'react-to-print';
import {GoogleSheet} from "../../components/common/GoogleSheet";
import {SpanIconBr} from "../../components/common/SpanIconBr";

function ProgramDetail(props) {
  let history = useHistory();
  const {loading, profile} = useAuthState();
  const [lessonList, setLessonList] = useState([]);
  const [sharedState, setSharedState] = useState({isVisible: false, bCopied: false, key: ''});
  const programData = useLocation().state.data
  const items = [
    {to: PageConstant.PROGRAMS, label: 'プログラム管理'},
    {to: '', label: programData.program_name},
  ]

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getParam = (programId) => {
    return {
      program_id: programId,
    }
  }

  const fetchLessonList = (program) => {
    getLessonList(getParam(program.id))
      .then(response => {
        if (!_.isEmpty(response.data)) {
          if (response.data.data) {
            setLessonList(response.data.data)
          }
        }
      })
  }

  const onShareClick = (e, item) => {
    e.preventDefault()
    let formData = new FormData()
    formData.append('program_id', item.id)
    addSharedProgramUrl(formData).then(response => {
      if (!_.isEmpty(response.data)) {
        if (response.data.key) {
          setSharedState({
            isVisible: true,
            bCopied: false,
            key: response.data.key,
          })
        }
      }
    })
  }

  const onPublishClick = (e, item) => {
    e.preventDefault()
    history.push(PageConstant.CERTIFICATE_PUBLISH, {data: programData})
  }

  const getUrl = () => {
    return window.location.host + PageConstant.SHARED_PROGRAM + "/" + sharedState.key
  }

  const onCopyUrl = (url) => {
    navigator.clipboard.writeText(url).catch(console.error)
    setSharedState({isVisible: sharedState.isVisible, bCopied: true, key: sharedState.key})
  }

  const onItemDetailClick = (e, item) => {
    e.preventDefault()
    history.push(PageConstant.LESSON_DETAIL, {data: programData, item: item})
  }

  useEffect(() => {
    fetchLessonList(programData)
  }, []);

  return (
    <Fragment>
      <div className={"px-32 pb-12 bg-yellow-light"}>
        <Breadcrumb items={items}/>
        {
          programData.program_name && (
            <div className={"flex justify-center items-center py-8"}>
              <span className={"font-bold text-black text-3xl"}>{programData.program_name}</span>
            </div>
          )
        }

        <div className={"flex items-center justify-end pb-8"}>
          <button
            className={"flex items-center justify-center bg-yellow btn-secondary-semi-round text-black font-bold text-sm h-50px mr-4"}
            style={{width: 190}}
            onClick={(e) => onShareClick(e, programData)}
          >
            <img alt={""} src={require('../../assets/images/upload.png')}/>
            <span className={"ml-2"}>プログラムの共有</span>
          </button>
          <button
            className={"flex items-center justify-center bg-white btn-primary-semi-round text-blue font-bold text-sm h-50px mr-4"}
            style={{width: 190}}
            onClick={(e) => onPublishClick(e, programData)}
          >
            <img alt={""} src={require('../../assets/images/flower.png')}/>
            <span className={"ml-2"}>修了証を発行</span>
          </button>
          <button
            className={"flex items-center justify-center bg-blue btn-primary-semi-round text-white font-bold text-sm h-50px"}
            style={{width: 190}}
            onClick={handlePrint}
          >
            <img alt={""} src={require('../../assets/images/printer.png')}/>
            <span className={"ml-2"}>プログラムを印刷</span>
          </button>
        </div>
        <div className={"rounded-xl bg-white py-16"} ref={componentRef}>
          <Bookmark title={"プログラム日程"}/>
          {
            programData.schedule && (
              <div className={"px-24 py-8"}>
                <SpanIconBr radio={true} name={programData.schedule}/>
              </div>
            )
          }
          <Bookmark title={"開催場所"}/>
          {
            programData.venue && (
              <div className={"px-24 py-8"} style={{fontSize: '16px'}}>
                <span>{programData.venue}</span>
              </div>
            )
          }
          <Bookmark title={"Todo・確認事項"}/>
          {
            programData.confirm_item && (
              <div className={"px-24 py-8"}>
                <SpanIconBr name={programData.confirm_item}/>
              </div>
            )
          }
          <Bookmark title={"流れについて"}/>
          <div className={"px-24 py-8"}>
            <GoogleSheet url={programData.flow_comment}/>
          </div>
          <Bookmark title={"授業"}/>
          {
            lessonList.map((item, key) => (
              <Fragment key={key}>
                <LessonItem
                  key={key}
                  item={item}
                  onItemDetailClick={onItemDetailClick}
                />
                {
                  key < lessonList.length - 1 && (
                    <div className={"bg-gray-600 h-px mx-24 mt-8"}/>
                  )
                }
              </Fragment>
            ))
          }
        </div>
        <div className={"flex justify-center pt-12"}>
          <Link
            to={PageConstant.PROGRAMS}
            className={"flex items-center justify-center bg-blue btn-primary-round h-75px text-white text-xl"}
            style={{width: 400}}
          >
            <span className={"mr-8"}>プログラム一覧へ戻る</span>
            <img alt="" src={require('../../assets/images/circle_arrow_yellow.svg')}/>
          </Link>
        </div>
      </div>
      <ProgramShare
        isModalVisible={sharedState.isVisible}
        intl={props.intl}
        bCopied={sharedState.bCopied}
        onCopyUrl={onCopyUrl}
        url={getUrl()}
        size={400}
        handleCancel={() => setSharedState({isVisible: false, bCopied: false, key: sharedState.key})}
      />
    </Fragment>
  )
}

export default injectIntl(ProgramDetail)
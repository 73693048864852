import React, {Fragment} from 'react';
import {Spin} from "antd";
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'


function ForgetPasswordEmailSend(props) {
  const items = [
    {to: '', label: 'パスワード再設定メール送信完了'},
  ]

  return (
    <Fragment>
      <div className={"px-6 md:px-32 pb-12 bg-yellow-light"}>
        <Breadcrumb items={items} />
        <div className="bg-white mx-auto" style={{ borderRadius: 20 }}>
          <Spin spinning={null} size="large">
            <div className={"py-12 px-0 md:px-48"}>
              <div className={"flex items-center justify-center"}>
                <span className={"text-light-blue text-2xl font-bold"}>
                  再設定メールを送信しました
                </span>
              </div>
              <div className={"bg-blue h-px my-4 mx-6 md:mx-0"} />
              <div className={"flex flex-col md:flex-row items-center my-5 px-6 md:px-12"}>
                <img
                  alt=""
                  src={require("../../assets/images/email_mark.svg")}
                  className={"mb-6 md:mb-0"}
                />
                <span className={"ml-0 md:ml-6 text-sm"}>
                  ご入力いただいたメールアドレスへ「パスワード再設定URL」を記載したメールをお送りいたしました。
                  受信後1時間以内にURLから再設定の手続きをお願いします。
                </span>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </Fragment>
  );
}

export default injectIntl(ForgetPasswordEmailSend)
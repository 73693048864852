import React, {Fragment, useRef} from 'react';
import {Col, Divider, Form, Row} from "antd";
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {useHistory} from "react-router-dom";
import {useAuthState} from "../../context";
import {PageConstant} from "../../constants/PageConstant";
import {FormInput} from "../../components/form/FormInput";
import {changeTeacherEmail} from "../../api/axiosAPIs";


function TeacherProfileEmail(props) {
  let history = useHistory();
  const formRef = useRef();
  const {loading, profile} = useAuthState();

  const items = [
    {to: PageConstant.TEACHER_PROFILE, label: '教員情報変更'},
    {to: '', label: 'メールアドレス変更'},
  ]

  const onFinish = (data) => {
    let formData = new FormData()
    formData.append('id', profile.teacher.id)
    formData.append('email', data.email)
    changeTeacherEmail(formData).then(response => {
      if (response.status === 200) {
        history.push(PageConstant.TEACHER_PROFILE_EMAIL_SEND)
      }
    })
  }

  return (
    <Fragment>
      <div className={"px-32 pb-12 bg-blue-light"}>
        <Breadcrumb items={items}/>
        <div className={"rounded-xl bg-white overflow-hidden"}>
          <div className={"flex items-center justify-center py-4 bg-profile-yellow"}>
            <span className={"ml-4 text-black text-lg font-bold"}>教員情報変更</span>
          </div>
          <div className={"px-32 py-16"}>
            <Form
              ref={formRef}
              onFinish={onFinish}
            >
              <Row>
                <Col span={6} offset={2}>
                  <div style={{marginLeft: 45}}>
                    <span className={"ml-2 text-light-blue text-base font-bold"}>メールアドレス変更</span>
                  </div>
                </Col>
                <Col span={12}/>
              </Row>
              <Divider/>
              {/*新しいメールアドレス*/}
              <FormInput
                label={"新しいメールアドレス"}
                name="email"
                placeholder="メールアドレス"
                intl={props.intl}
                required={true}
                readOnly={false}
              />
              <Divider/>
              <Row>
                <Col span={18} offset={2}>
                  <div style={{marginLeft: 45}}>
                      <span
                        className={"text-black text-sm"}>送信後、ご入力いただいたメールアドレスに変更確認メールをお送りします。受信設定をされている場合は、事前にinfo@mietaplus.comからメールの受信が可能かどうか確認をお願いいたします。</span>
                  </div>
                </Col>
                <Col span={12}/>
              </Row>

              <Row>
                <Col span={8} offset={8}>
                  <button
                    type="submit"
                    className={"mt-10 flex items-center justify-center btn-primary-semi-round bg-blue"}
                    style={{width: 315, height: 55}}
                  >
                    <span className={"text-white text-base font-bold"}>確認メールを送信する</span>
                  </button>
                </Col>
              </Row>

            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default injectIntl(TeacherProfileEmail)
import React, {Fragment} from 'react';
import {HeaderMenuItem} from "./HeaderMenuItem";
import {PageConstant} from "../../constants/PageConstant";
import {useAuthState} from "../../context";

export const HeaderMenu = (props) => {
  const {profile} = useAuthState();
  return (
    <Fragment>
      <div
        className={"bg-yellow-light flex items-center justify-center h-40px"}
      >
        <span className="text-yellow text-base font-bold">
          探究学習メニュー
        </span>
      </div>
      <div
        className={
          "px-6 md:px-32 bg-yellow flex items-center justify-center h-75px"
        }
      >
        {/*コラム*/}
        <HeaderMenuItem
          url={PageConstant.COLUMNS}
          image={require("../../assets/images/header_colum.svg")}
          class={"hidden md:block"}
        />
        <HeaderMenuItem
          url={PageConstant.COLUMNS}
          image={require("../../assets/images/header_colum_sp.svg")}
          class={"block md:hidden"}
        />
        {/*MIETAN教材*/}
        <HeaderMenuItem
          url={PageConstant.THEMES}
          image={require("../../assets/images/header_theme.svg")}
          class={"hidden md:block"}
        />
        <HeaderMenuItem
          url={PageConstant.THEMES}
          image={require("../../assets/images/header_theme_sp.svg")}
          class={"block md:hidden border-x-1 md:border-0"}
        />
        {/*プログラム管理*/}
        {profile.teacher.school_id && (
          <HeaderMenuItem
            url={PageConstant.PROGRAMS}
            image={require("../../assets/images/header_program.svg")}
            class={"hidden md:block"}
          />
        )}
        {profile.teacher.school_id && (
          <HeaderMenuItem
            url={PageConstant.PROGRAMS}
            image={require("../../assets/images/header_program_sp.svg")}
            class={"block md:hidden"}
          />
        )}
        {!profile.teacher.school_id && (
          <HeaderMenuItem
            url={PageConstant.TEASER_PROGRAM}
            lockImage={require("../../assets/images/lock.png")}
            image={require("../../assets/images/header_program_lock.png")}
          />
        )}
      </div>
    </Fragment>
  );
}

import React, {Fragment, useRef, useState} from 'react';
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {Link, useHistory, useLocation} from "react-router-dom";
import {useAuthState} from "../../context";
import {PageConstant} from "../../constants/PageConstant";
import {Bookmark} from "../../components/bookmark/Bookmark";
import {LessonItem} from "../../components/program/LessonItem";
import {SpanIconBr} from "../../components/common/SpanIconBr";
import YoutubeEmbed from "../../components/youtube/YoutubeEmbed";

function TeaserProgram(props) {
  let history = useHistory();
  const {loading, profile} = useAuthState();

  const programData = {
    program_name: '社会テーマ体験WS ＆ 座談会',
    schedule: '2022/04/11：講師・テーマ選択\n2022/04/25：事前ワーク\n2022/05/09：本講義',
    venue: '各教室及び各会場(本講義は3会場に分かれて実施します)',
    confirm_item: '生徒の希望テーマ調査(別途送付済のGoogleフォームを仕様)\n生徒の希望テーマの人数調整\n5月9日の大規模会場(3箇所)の確保\n4月25日の各教室での映像配信環境の準備\n4月29日までに事前ワークのアップロード',
    flow_comment: '4月11日のホームルームにて、以下の3名の講師の紹介動画を見て、最も興味のある講師・テーマを選択して頂きます。その後、事前ワークと本講義は本ページ下部の「授業」から選択した講師・テーマのページに記載してある内容に従って、ワークを進めていきます',
  }

  const items = [
    {to: '', label: 'プログラム管理'},
    {to: '', label: programData.program_name},
  ]

  const lessonList = [
    {
      lesson_name: '世界の課題を解決する新しい国際開発の形',
      lesson_image_urls: [
        require('../../assets/images/lesson_example1.png'),
        require('../../assets/images/lesson_example2.png'),
      ],
      teaser: true,
      locked: false,
      instructors: [
        {
          instructor_thumbnail_url: require('../../assets/images/instructor_example1.png'),
          instructor_name: '秋間早苗',
          instructor_eng_name: 'AKIMA SANAE',
          venue: 'SXデザイナー / 株式会社La Touche CEO',
        }
      ],
    },
    {
      lesson_name: 'おもちゃ開発を通して学ぶアイデア発想術',
      lesson_image_urls: [
        require('../../assets/images/instructor_example2.png'),
      ],
      teaser: false,
      locked: true,
      instructors: [
        {
          instructor_thumbnail_url: require('../../assets/images/instructor_example2.png'),
          instructor_name: '高橋晋平',
          instructor_eng_name: 'Takahashi Shinpei',
          venue: 'おもちゃクリエーター / 株式ウサギ 代表取締役',
        }
      ],
    },
    {
      lesson_name: '映像のチカラで日本を元気に！',
      lesson_image_urls: [
        require('../../assets/images/instructor_example3.png'),
      ],
      teaser: false,
      locked: true,
      instructors: [
        {
          instructor_thumbnail_url: require('../../assets/images/instructor_example3.png'),
          instructor_name: '秋間早苗',
          instructor_eng_name: 'AKIMA SANAE',
          venue: 'SXデザイナー / 株式会社La Touche CEO',
        }
      ],
    },
  ]

  const componentRef = useRef();
  const onItemDetailClick = (e, item) => {
    e.preventDefault()
    history.push(PageConstant.TEASER_LESSON, {data: programData, item: item})
  }

  return (
    <Fragment>
      <div className={"px-32 pb-12 bg-yellow-light"}>
        <Breadcrumb items={items}/>
        {
          programData.program_name && (
            <div className={"flex justify-center items-center py-8"}>
              <span className={"font-bold text-black text-3xl"}>{programData.program_name}</span>
            </div>
          )
        }

        <div className={"flex items-center justify-end pb-8"}>
          <button
            className={"flex items-center justify-center bg-yellow btn-secondary-semi-round text-black font-bold text-sm h-50px mr-4"}
            style={{width: 190}}
          >
            <img alt={""} src={require('../../assets/images/upload.png')}/>
            <span className={"ml-2"}>プログラムの共有</span>
          </button>
          <button
            className={"flex items-center justify-center bg-white btn-primary-semi-round text-blue font-bold text-sm h-50px mr-4"}
            style={{width: 190}}
          >
            <img alt={""} src={require('../../assets/images/flower.png')}/>
            <span className={"ml-2"}>修了証を発行</span>
          </button>
          <button
            className={"flex items-center justify-center bg-blue btn-primary-semi-round text-white font-bold text-sm h-50px"}
            style={{width: 190}}
          >
            <img alt={""} src={require('../../assets/images/printer.png')}/>
            <span className={"ml-2"}>プログラムを印刷</span>
          </button>
        </div>
        <div className={"rounded-xl bg-white py-16"} ref={componentRef}>
          <Bookmark title={"プログラム日程"}/>
          {
            programData.schedule && (
              <div className={"px-24 py-8"}>
                <SpanIconBr radio={true} name={programData.schedule}/>
              </div>
            )
          }
          <Bookmark title={"開催場所"}/>
          {
            programData.venue && (
              <div className={"px-24 py-8 text-base"}>
                <span>{programData.venue}</span>
              </div>
            )
          }
          <Bookmark title={"Todo・確認事項"}/>
          {
            programData.confirm_item && (
              <div className={"px-24 py-8"}>
                <SpanIconBr check={true} name={programData.confirm_item}/>
              </div>
            )
          }
          <Bookmark title={"流れについて"}/>
          <div className={"px-24 py-8"}>
            <div className={"text-base"}>{programData.flow_comment}</div>
            <div className={"pt-12 font-bold text-base"}>A：秋間早苗 講師</div>
            <div className={"px-12 py-8"}>
              <YoutubeEmbed embedId="gGHmFO5E61k"/>
            </div>
            <div className={"pt-12 font-bold text-base"}>B：高橋晋平 講師</div>
            <div className={"px-12 py-8"}>
              <YoutubeEmbed embedId="Ktb-pNENtXo"/>
            </div>
            <div className={"pt-12 font-bold text-base"}>C：マイアミ啓太 講師</div>
            <div className={"px-12 py-8"}>
              <YoutubeEmbed embedId="GpVYAnEZVsE"/>
            </div>
          </div>
          <Bookmark title={"授業"}/>
          {
            lessonList.map((item, key) => (
              <Fragment key={key}>
                <LessonItem
                  key={key}
                  item={item}
                  teaser={item.teaser}
                  locked={item.locked}
                  onItemDetailClick={onItemDetailClick}
                />
                {
                  key < lessonList.length - 1 && (
                    <div className={"bg-gray-600 h-px mx-24"}/>
                  )
                }
              </Fragment>
            ))
          }
        </div>
      </div>
    </Fragment>
  )
}

export default injectIntl(TeaserProgram)
import React, {Fragment} from 'react';
import {Spin} from "antd";
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {PageConstant} from "../../constants/PageConstant";


function SignupVirtualRegister(props) {
  const items = [
    {to: PageConstant.SIGNUP, label: '新規会員登録'},
    {to: '', label: '仮登録完了'},
  ]

  return (
    <Fragment>
      <div className={"px-6 md:px-32 pb-12 bg-yellow-light"}>
        <Breadcrumb items={items} />
        <div
          className="bg-white mx-auto w-full md:w-500px"
          style={{ borderRadius: 20, overflow: "hidden" }}
        >
          <div
            className={"flex items-center justify-center bg-blue h-8 md:h-20px"}
          ></div>
          <Spin spinning={null} size="large">
            <div className={"p-6i md:p-35pxi"}>
              <div className={"flex items-center justify-center"}>
                <span
                  className={
                    "text-light-blue text-2xl font-bold tracking-widest md:tracking-normal"
                  }
                >
                  仮登録が完了しました
                </span>
              </div>
              <div className={"bg-blue h-px my-4"} />
              <div className={"flex justify-center my-5"}>
                <span className={"text-lg font-bold under-padding-line-lg"}>
                  &nbsp;本登録はまだ完了しておりません&nbsp;
                  <span className={" hidden md:visible"}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
              </div>
              <div className={"flex flex-col md:flex-row items-center my-5"}>
                <img
                  alt=""
                  src={require("../../assets/images/email_mark.svg")}
                />
                <span
                  className={
                    "mt-4 md:mt-0 ml-0 md:ml-6 text-sm under-padding-line"
                  }
                >
                  ご登録いただいたメールアドレスに本登録の手順をお送りさせていただきましたので、ご確認をお願いいたします。&nbsp;
                </span>
              </div>
              <div>
                <span>
                  ※
                  お使いのメールソフトによっては、メールが「迷惑メール」フォルダに入る場合がございます。メールが届かない場合は、迷惑フォルダもご確認ください。
                </span>
              </div>
              <div>
                <span>
                  ※
                  本登録におきましても、引き続きパソコン用のブラウザで行っていただく必要がございます。（スマートフォン用のブラウザには対応しておりません。）
                </span>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </Fragment>
  );
}

export default injectIntl(SignupVirtualRegister)
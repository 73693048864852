import React, { Fragment, useEffect, useRef, useState } from "react";
import { Card, Checkbox, Col, Form, Row } from "antd";
import { Breadcrumb } from "../../components/breadcrumb/Breadcrumb";
import { injectIntl } from "react-intl";
import { FormInput } from "../../components/form/FormInput";
import { FormSchool } from "../../components/form/FormSchool";
import { ModalSchool } from "../../components/modal/ModalSchool";
import { useHistory } from "react-router-dom";
import { useAuthState } from "../../context";
import { PageConstant } from "../../constants/PageConstant";
import { FormInputPassword } from "../../components/form/FormInputPassword";
import { getProfile } from "../../api/axiosAPIs";
import _ from "lodash";
import { FormTextArea } from "../../components/form/FormTextArea";

function SignupForm(props) {
  let history = useHistory();
  const { loading } = useAuthState();
  const formRef = useRef();
  const [modalData, setModalData] = useState({
    isVisible: false,
    school: { school_name: "", sId: 0, csId: 0, pId: 0 },
  });
  const [isConfirm, setIsConfirm] = useState(false);
  const [profile, setProfile] = useState({});

  const items = [
    { to: PageConstant.SIGNUP, label: "新規会員登録" },
    { to: "", label: "本登録" },
  ];

  const onChangeInputConfirm = (e) => {
    setIsConfirm(e);
  };

  const onClickSchool = () => {
    setModalData({
      isVisible: true,
      school: { school_name: "", sId: 0, csId: 0, pId: 0 },
    });
    if (formRef.current) {
      formRef.current.setFieldsValue({
        school: "",
      });
    }
  };

  const onSelectSchool = (e, school) => {
    setModalData({ isVisible: false, school: school });
    if (formRef.current) {
      formRef.current.setFieldsValue({
        school: school.school_name,
      });
    }
  };

  const onResetSchool = (e) => {
    if (formRef.current) {
      formRef.current.setFieldsValue({
        school: "",
      });
    }
  };

  const onFinish = (data) => {
    data.school_id = modalData.school.school_id;
    data.csid = modalData.school.csid ? modalData.school.csid : 0;
    data.pid = modalData.school.pid;
    history.push(PageConstant.SIGNUP_FORM_CONFIRM, {
      data: data,
      profile: profile,
    });
  };

  useEffect(() => {
    getProfile().then((response) => {
      if (!_.isEmpty(response.data)) {
        if (response.data.data) {
          setProfile(response.data.data);
        }
      }
    });
  }, []);

  return (
    <Fragment>
      <div className={"px-6 md:px-32 pb-12 bg-yellow-light"}>
        <Breadcrumb items={items} />
        <div className={"flex items-center justify-center mb-6 md:mb-10"}>
          <span className={"blue-color text-lg font-bold"}>本登録</span>
        </div>
        <Card style={{ borderRadius: 20 }} className={"p-0i md:p-35pxi"}>
          <Form
            ref={formRef}
            initialValues={{ subject: "", class: "" }}
            onFinish={onFinish}
          >
            {profile.pattern === "a" && (
              <Fragment>
                {/*教員氏名*/}
                <FormInput
                  label={props.intl.formatMessage({
                    id: "form.item.teacher.name",
                  })}
                  name="teacher_name"
                  placeholder={props.intl.formatMessage({
                    id: "form.item.teacher.name",
                  })}
                  intl={props.intl}
                  required={true}
                  readOnly={false}
                />
                {/*所属学校*/}
                <FormSchool
                  label={props.intl.formatMessage({ id: "form.item.school" })}
                  name="school"
                  placeholder="所属学校を検索する"
                  intl={props.intl}
                  required={true}
                  onHandleClick={onClickSchool}
                  onHandleCancel={onResetSchool}
                />
              </Fragment>
            )}
            {/*担当科目*/}
            <FormTextArea
              label={props.intl.formatMessage({
                id: "form.item.teacher.subject",
              })}
              name="subject"
              placeholder={props.intl.formatMessage({
                id: "form.item.teacher.subject",
              })}
              intl={props.intl}
              required={false}
            />
            {/*担当クラス*/}
            {/*<FormInput*/}
            {/*  label={props.intl.formatMessage({id: 'form.item.teacher.class'})}*/}
            {/*  name="class"*/}
            {/*  placeholder={props.intl.formatMessage({id: 'form.item.teacher.class'})}*/}
            {/*  intl={props.intl}*/}
            {/*  required={false}*/}
            {/*/>*/}
            {/*初期パスワード*/}
            <FormInputPassword
              label={props.intl.formatMessage({
                id: "form.item.init.password",
              })}
              name="password"
              placeholder={props.intl.formatMessage({
                id: "form.item.init.password",
              })}
              intl={props.intl}
              required={true}
            />
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 17, offset: 7 }}
            >
              <div className={"flex items-center mt-5 mb-10"}>
                <img alt="" src={require("../../assets/images/require.svg")} />
                <div className={"ml-3"}>
                  <Checkbox onChange={onChangeInputConfirm}>
                    <span className={"text-base ml-1"}>
                      プライバシーポリシーに同意します
                    </span>
                  </Checkbox>
                </div>
              </div>
            </Col>
            <Row>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 8, offset: 7 }}
              >
                <button
                  type="submit"
                  disabled={!isConfirm}
                  className={
                    "flex items-center justify-center btn-secondary-semi-round text-yellow text-lg w-full md:w-315px mb-4 md:mb-0"
                  }
                  style={{ height: 55 }}
                >
                  <span>入力内容を確認する</span>
                </button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
      <ModalSchool
        isModalVisible={modalData.isVisible}
        intl={props.intl}
        onSelectSchool={onSelectSchool}
        handleCancel={() =>
          setModalData({ isVisible: false, school: modalData.school })
        }
      />
    </Fragment>
  );
}

export default injectIntl(SignupForm);

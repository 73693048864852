import React, {Fragment, useEffect, useState} from 'react';
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {Link, useHistory, useLocation} from "react-router-dom";
import {PageConstant} from "../../constants/PageConstant";
import {Slide} from "../../components/Slide/Slide";
import {Tag} from "../../components/tag/Tag";
import {ThemeBox} from "../../components/theme/ThemeBox";
import {InstructorItem} from "../../components/Instructor/InstructorItem";
import {Col, Form, Input, Row} from "antd";
import {getThemeItem, sendInquiry} from "../../api/axiosAPIs";
import _ from "lodash";
import {useAuthState} from "../../context";

const {TextArea} = Input;

function ThemeDetail(props) {
  let history = useHistory();
  const {loading, profile} = useAuthState();
  const data = useLocation().state.data
  const [row, setRow] = useState({});
  const items = [
    {to: PageConstant.THEMES, label: 'MIETAN教材'},
    {to: '', label: data.theme_name},
  ]

  const onInquirySend = (fData) => {
    const formData = new FormData()
    formData.append('inquiry_name', profile.name)
    formData.append('school_name', profile.school_name)
    formData.append('email', profile.email)
    formData.append('content', fData.content)
    sendInquiry(formData).then(response => {
      if (response.status === 200) {
        history.push(PageConstant.THEME_INQUIRY_SEND_FINISH, {item: data})
      }
    })
  }

  const onInstructorItemClick = (e, item) => {
    history.push(PageConstant.INSTRUCTOR_DETAIL, {data: item})
  }

  const fetchThemeItem = (id) => {
    getThemeItem(id)
      .then(response => {
        if (!_.isEmpty(response.data)) {
          if (response.data.data) {
            setRow(response.data.data)
          }
        }
      })
  }

  useEffect(() => {
    fetchThemeItem(data.id)
  }, []);

  return (
    <Fragment>
      <div className={"px-6 md:px-32 pb-0 md:pb-12 bg-yellow-light"}>
        <Breadcrumb items={items} />
        <div
          className={"bg-white"}
          style={{ borderRadius: 20, overflow: "hidden" }}
        >
          {row.theme_image_urls && row.theme_image_urls.length > 0 && (
            <Slide
              initialSlide={0}
              imageList={
                row.theme_image_urls.length > 0
                  ? row.theme_image_urls.slice(0, 3)
                  : [require("../../assets/images/column_thumbnail.png")]
              }
            />
          )}
          <div className={"px-0 md:px-24"}>
            <div className={"px-6 md:px-24"}>
              <div className={"flex items-center pb-4 md:py-8"}>
                {row.tag &&
                  row.tag.split(",").map((item, key) => (
                    <div key={key} className={"mr-3"}>
                      <Tag item={item} />
                    </div>
                  ))}
              </div>
              <div className={"pb-8"}>
                <ThemeBox
                  title={"ジャンル：" + (row.genre_name ? row.genre_name : "")}
                />
              </div>
              <div className={"pb-4 md:pb-16 text-base md:text-lg md:text-3xl font-bold"}>
                <span>{row.theme_name ? row.theme_name : ""}</span>
              </div>
              {row.content && (
                <div
                  className="ck-content pb-8"
                  dangerouslySetInnerHTML={{
                    __html: data.content.replace(/(?:\r\n|\r|\n)/g, "<br>"),
                  }}
                ></div>
              )}
            </div>
            {/*<div className={"px-12 pb-12"}>*/}
            {/*  <YoutubeEmbed embedId="iSRgBovXp5s"/>*/}
            {/*</div>*/}
            <div className={"px-2 md:px-12 pb-6"}>
              {row.instructors &&
                row.instructors.map((item, key) => (
                  <div key={key} className={"pb-6"}>
                    <InstructorItem
                      item={item}
                      onItemClick={(e) => onInstructorItemClick(e, item)}
                    />
                  </div>
                ))}
            </div>
            <div className={"mx-3 md:mx-0 px-3 md:px-12 mb-16 bg-yellow pt-4 pb-12 md:py-12"}>
              <div className={"flex items-center justify-center py-4"}>
                <span className={"font-bold text-lg md:text-2xl"}>
                  このテーマに問い合わせをする
                </span>
              </div>
              <div>
                <Form onFinish={onInquirySend}>
                  <Form.Item
                    name={"content"}
                    rules={[
                      {
                        required: true,
                        message: props.intl.formatMessage({
                          id: "alert.fieldRequired",
                        }),
                      },
                    ]}
                  >
                    <TextArea
                      autoSize={{ minRows: 15, maxRows: 15 }}
                      style={{ borderRadius: 20, padding: 30 }}
                      placeholder={"お問い合わせ内容をご入力ください"}
                    />
                  </Form.Item>
                  <Row justify={"center"}>
                    <Col className={"w-full md:w-auto"}>
                      <button
                        type="submit"
                        className={
                          "flex items-center justify-center bg-blue btn-primary-semi-round w-full md:w-315px text-white text-lg"
                        }
                        style={{ height: 55 }}
                      >
                        <span>送信する</span>
                      </button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div className={"flex justify-center py-16"}>
          <Link
            to={PageConstant.THEMES}
            className={
              "flex items-center justify-center bg-blue btn-primary-round h-75px text-white text-xl w-full md:w-450px"
            }

          >
            <span className={"mr-8"}>MIETAN教材一覧に戻る</span>
            <img
              alt=""
              src={require("../../assets/images/circle_arrow_yellow.svg")}
            />
          </Link>
        </div>
      </div>
    </Fragment>
  );
}

export default injectIntl(ThemeDetail)
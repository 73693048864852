import React, {Fragment} from 'react';
import {Card, Col, Row, Spin} from "antd";
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {useHistory} from "react-router-dom";
import {PageConstant} from "../../constants/PageConstant";
import InquiryFormTitle from "./inquiryFormTitle";


function InquiryFormSendFinish(props) {
  let history = useHistory();

  const items = [
    {to: PageConstant.INQUIRY_FORM, label: 'お問い合わせ'},
    {to: '', label: '送信完了'},
  ]

  const onOk = () => {
    history.push(PageConstant.HOME)
  }

  return (
    <Fragment>
      <div className={"px-32 pb-12 bg-yellow-light"}>
        <Breadcrumb items={items}/>
        <InquiryFormTitle/>
        <Card style={{borderRadius: 20, padding: 30}}>
          <Spin spinning={null} size="large">
            <div className={"flex items-center justify-center "}>
              <span className={"text-light-blue text-lg font-bold"}>お問い合わせいただきありがとうございます</span>
            </div>
            <div className={"bg-blue h-px my-4 mx-32"}/>
            <span className={"flex justify-center text-sm py-1"}>内容を確認後、折り返しご連絡させていただきます。</span>
            <span className={"flex justify-center text-sm py-1"}>※お問い合わせ状況やご質問内容によって、</span>
            <span className={"flex justify-center text-sm py-1"}>お返事を差し上げるのにお時間がかかってしまう場合がございます。</span>
          </Spin>
        </Card>
        <Row>
          <Col span={8} offset={8}>
            <div className={"mt-10 flex items-center justify-center"}>
              <button className={"flex items-center justify-center bg-blue btn-primary-round h-75px text-white text-xl"}
                      style={{width: 350}}
                      onClick={onOk}
              >
                <span className={"mr-8"}>トップページへ</span>
                <img alt="" src={require('../../assets/images/circle_arrow_yellow.svg')}/>
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  )
}

export default injectIntl(InquiryFormSendFinish)
import React, {Fragment} from 'react';
import styled from "styled-components";

const ImgContainer = styled.div`
  width: 88px;
  height: 88px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  margin-left: 5px;
  margin-top: -29px;
  background-position: center center;
  background-repeat: no-repeat;
`
const Avatar = styled.img`
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: 100%;
`
const AvatarBorder = styled.img`
  width: 102px;
  height: 127px;
`

export const InstructorItem = (props) => {
  return (
    <Fragment>
      <div
        className={"bg-yellow-light py-4 px-4 flex items-center"}
        style={{ borderRadius: 10, overflow: "hidden" }}
      >
        {props.item.instructor_thumbnail_url && (
          <ImgContainer className={"ios-margintop-14"}>
            <Avatar
              alt=""
              src={props.item.instructor_thumbnail_url}
              className={"object-cover md:object-fill"}
            />
          </ImgContainer>
        )}
        <AvatarBorder
          alt=""
          src={require("../../assets/images/bg_instructor.png")}
        />
        <div className={""} style={{ height: 127 }}>
          <div>
            <span className={"px-2 md:px-4 text-sm text-black"}>講師</span>
          </div>
          <div>
            <span className={"px-2 md:px-4 text-base font-bold text-black"}>
              {props.item.instructor_name ? props.item.instructor_name : ""}
            </span>
          </div>
          <div>
            <span className={"px-2 md:px-4 text-xs text-gray-600"}>
              {props.item.instructor_eng_name
                ? props.item.instructor_eng_name
                : ""}
            </span>
          </div>
          <div className={"bg-yellow h-px"} />
          <div>
            <span
              className={"px-2 md:px-4 text-sm text-black block "}
              style={{ wordWrap: "break-word" }}
            >
              {props.item.venue ? props.item.venue : ""}
            </span>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

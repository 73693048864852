import React, {Fragment} from 'react';
import {Spin} from "antd";
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {PageConstant} from "../../constants/PageConstant";


function TeacherManageAddSend(props) {
  const items = [
    {to: PageConstant.TEACHER_MANAGE, label: '教員管理'},
    {to: '', label: '教員アカウント新規発行（仮登録）完了'},
  ]

  return (
    <Fragment>
      <div className={"px-32 pb-12 bg-blue-light"}>
        <Breadcrumb items={items}/>
        <div className={"rounded-xl bg-white overflow-hidden"}>
          <Spin spinning={null} size="large">
            <div className={"flex items-center justify-center py-4 bg-blue"}>
              <span className={"ml-4 text-white text-lg font-bold"}>教員管理</span>
            </div>
            <div className={"py-12 px-48"}>
              <div className={"flex items-center justify-center py-4"}>
                <span className={"text-light-blue text-2xl font-bold"}>教員アカウントを新規発行しました（仮登録）</span>
              </div>
              <div className={"flex items-center justify-center"}>
                <span className={"text-light-blue text-lg font-bold"}>※本登録はまだ完了しておりません</span>
              </div>
              <div className={"bg-blue h-px my-4"}/>
              <div className={"flex items-center my-5 px-12"}>
                <img alt="" src={require('../../assets/images/email_mark.png')}/>
                <div className={"ml-6"}>
                  <div className={"py-2"}>
                    <span style={{fontSize: 15}}>ご登録いただいたメールアドレスに本登録の手順をお送りさせていただきましたので、ご確認をお願いいたします。</span>
                  </div>
                  <div>
                    <span style={{fontSize: 13}}>※ お使いのメールソフトによっては、メールが「迷惑メール」フォルダに入る場合がございます。メールが届かない場合は、迷惑フォルダもご確認ください。</span>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </Fragment>
  )
}

export default injectIntl(TeacherManageAddSend)
import React, {Fragment, useState} from 'react';
import {Col, Form, Radio, Row, Spin} from "antd";
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {useHistory, useLocation} from "react-router-dom";
import {useAuthState} from "../../context";
import {PageConstant} from "../../constants/PageConstant";
import {FormInput} from "../../components/form/FormInput";
import {FormLabel} from "../../components/form/FormLabel";
import {DeleteConfirm} from "../../components/modal/DeleteConfirm";
import {deleteTeacher, editTeacher} from "../../api/axiosAPIs";
import _ from "lodash";
import {T_Admin, T_Viewer} from "../../constants/Permission";
import {FormTextArea} from "../../components/form/FormTextArea";


function TeacherManageEdit(props) {
  let history = useHistory();
  const {loading, profile} = useAuthState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const initData = useLocation().state.data

  const items = [
    {to: PageConstant.TEACHER_MANAGE, label: '教員管理'},
    {to: '', label: '教員情報の編集'},
  ]

  const onSave = (data) => {
    data.prefecture_id = initData.prefecture_id
    data.school_id = initData.school_id
    data.contracted_school_id = initData.contracted_school_id
    editTeacher(initData.id, data).then(response => {
      if (!_.isEmpty(response.data)) {
        history.push(PageConstant.TEACHER_MANAGE)
      }
    })
  }

  const onDelete = () => {
    deleteTeacher(initData.id).then(response => {
      if (!_.isEmpty(response.data)) {
        history.push(PageConstant.TEACHER_MANAGE)
      }
    })
  }

  return (
    <Fragment>
      <div className={"px-32 pb-12 bg-blue-light"}>
        <Breadcrumb items={items}/>
        <div className={"rounded-xl bg-white overflow-hidden"}>
          <Spin spinning={null} size="large">
            <div className={"flex items-center justify-center py-4 bg-blue"}>
              <span className={"ml-4 text-white text-lg font-bold"}>教員管理</span>
            </div>
            <div className={"py-12 px-32"}>
              <div className={"mb-8"}>
                <span className={"text-base font-bold text-blue"}>教員情報の編集</span>
              </div>
              <Form
                initialValues={initData}
                onFinish={onSave}
              >
                <div className={"rounded-xl pt-10 pb-4 mb-4"} style={{background: '#F8F8F8'}}>
                  {/*教員氏名*/}
                  <FormInput
                    label={props.intl.formatMessage({id: 'form.item.teacher.name'})}
                    placeholder={props.intl.formatMessage({id: 'form.item.teacher.name'})}
                    name={'teacher_name'}
                    intl={props.intl}
                    required={true}
                  />
                  {/*担当科目*/}
                  <FormTextArea
                    label={props.intl.formatMessage({id: 'form.item.teacher.subject'})}
                    placeholder={props.intl.formatMessage({id: 'form.item.teacher.subject'})}
                    name={'subject_name'}
                    intl={props.intl}
                    required={false}
                  />
                  {/*担当クラス*/}
                  {/*<FormInput*/}
                  {/*  label={props.intl.formatMessage({id: 'form.item.teacher.class'})}*/}
                  {/*  placeholder={props.intl.formatMessage({id: 'form.item.teacher.class'})}*/}
                  {/*  name={'class_name'}*/}
                  {/*  intl={props.intl}*/}
                  {/*  required={false}*/}
                  {/*/>*/}
                  <Row>
                    <FormLabel label={'権限'} required={true}/>
                    <Col span={12}>
                      <Form.Item
                        name={'permission'}
                        initialValue={9}
                        required={true}
                      >
                        <Radio.Group>
                          <Radio value={T_Admin} style={{width: 200}}>管理者</Radio>
                          <Radio value={T_Viewer}>閲覧のみ</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                {
                  profile.permission > T_Viewer && (
                    <div className={"flex justify-center w-full"}>
                      <button
                        type="submit"
                        className={"flex items-center justify-center btn-secondary-semi-round bg-yellow"}
                        style={{width: 250, height: 55}}
                      >
                        <span className={"text-black text-base font-bold"}>変更する</span>
                      </button>
                      <div
                        className={"ml-16 flex items-center justify-center btn-primary-semi-round cursor-pointer"}
                        style={{width: 250, height: 55}}
                        onClick={() => setIsModalVisible(true)}
                      >
                        <span className={"text-blue text-base font-bold"}>教員アカウントの削除</span>
                      </div>
                    </div>
                  )
                }
              </Form>
            </div>
          </Spin>
        </div>
      </div>
      <DeleteConfirm
        isModalVisible={isModalVisible}
        handleCancel={() => setIsModalVisible(false)}
        onDelete={onDelete}
      />
    </Fragment>
  )
}

export default injectIntl(TeacherManageEdit)
import React, {Fragment} from 'react';
import {useHistory} from "react-router-dom";
import {PageConstant} from "../../constants/PageConstant";
import {ThemeList} from "../theme/ThemeList";

export const ThemeLayout = (props) => {
  let history = useHistory();
  const onClickTheme = () => {
    history.push(PageConstant.THEMES, {cache: Math.random().toString()})
  }

  return (
    <Fragment>
      <div className={"bg-home-theme"}>
        <div className="px-6 md:px-32">
          <div className={"absolute right-0"}>
            <div className={"justify-end flex px-32"}>
              <img
                alt=""
                src={require("../../assets/images/symbol_theme.svg")}
              />
            </div>
          </div>
          <div className="pt-10">
            <div className={"flex justify-center pb-5"}>
              <img alt="" src={require("../../assets/images/mark_theme.svg")} />
            </div>
          </div>
          <div>
            <ThemeList themeList={props.themeList} />
          </div>
        </div>
        <div className={"flex justify-center py-16"}>
          <div
            onClick={onClickTheme}
            className={
              "flex items-center justify-center bg-yellow btn-secondary-round h-75px text-white text-xl cursor-pointer w-full md:w-450px px-6 md:px-0"
            }
          >
            <span className={"mr-8"}>MIETAN教材一覧</span>
            <img
              alt=""
              src={require("../../assets/images/circle_arrow_blue.png")}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

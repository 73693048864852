/**
 * Axios related configurations here
 */

require('dotenv').config()

export const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5000/'

export const SIGNUP = `signup`
export const SIGNUP_CONFIRM = `signup_confirm`
export const LOGIN = `login`
export const LOGOUT = `logout`
export const PASSWORD_RESETS = `password_resets`
export const INQUIRY = `inquiry`
export const SCHOOL = 'schools'
export const PROGRAM = 'programs'
export const LESSON = 'lessons'
export const THEME = 'themes'
export const THEME_BY_INSTRUCTOR = 'themes_by_instructor'
export const INSTRUCTOR = 'instructors'
export const TEACHER = 'teachers'
export const TEACHER_EXIST = 'teachers_exist'
export const TEACHER_EMAIL = 'teachers_email'
export const TEACHER_PASSWORD = 'teachers_password'
export const STAFF = 'users'
export const STUDENT = 'students'
export const NOTIFICATION = 'notifications'
export const PREFECTURE = 'prefecture'
export const GENRE = 'genre'
export const HOME = 'home'
export const PROFILE = 'profile'
export const SHARED_PROGRAM = 'share_program'
export const SHARED_LESSON = 'share_lesson'
export const SHARED_INSTRUCTOR = 'share_instructor'
export const CERTIFICATE_PUBLISH = 'certificate_publish'

import React, {Fragment, useEffect} from 'react';
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {Link, useHistory, useLocation} from "react-router-dom";
import {useAuthState} from "../../context";
import {PageConstant} from "../../constants/PageConstant";
import {ColumnBox} from "../../components/column/ColumnBox";
import {Tag} from "../../components/tag/Tag";

function ColumnDetail(props) {
  let history = useHistory();
  const {loading, profile} = useAuthState();
  const data = useLocation().state.data;

  const items = [
    {to: PageConstant.COLUMNS, label: 'コラム'},
    {to: '', label: data.title},
  ]

  useEffect(() => {
  }, []);

  return (
    <Fragment>
      <div className={"px-6 md:px-32 pb-12 bg-yellow-light"}>
        <Breadcrumb items={items} />
        <div>
          <div
            className={"bg-white px-6 md:px-40 py-6 md:py-24"}
            style={{ borderRadius: 20, overflow: "hidden" }}
          >
            <div
              className={
                "center-cropped-wrapper h-columndetail md:h-columndetail"
              }
            >
              <img
                alt=""
                className={"center-cropped w-full"}
                src={
                  data.notification_image_url !== ""
                    ? data.notification_image_url
                    : require("../../assets/images/column_thumbnail.png")
                }
              />
            </div>
            <div className={"my-6"}>
              <ColumnBox title={data.public_start_date} />
            </div>
            <div className={"my-4"}>
              <span className={"text-lg md:text-2xl"}>{data.title}</span>
            </div>
            <div className={"my-4"}>
              <Tag item={data.slug} />
            </div>
            <div className={"bg-black h-px"} />
            {data.content && (
              <div className={"my-6"}>
                <div
                  className="ck-content"
                  dangerouslySetInnerHTML={{
                    __html: data.content.replace(/(?:\r\n|\r|\n)/g, "<br>"),
                  }}
                ></div>
              </div>
            )}
          </div>
        </div>
        <div className={"flex items-center justify-center pt-12"}>
          <Link
            to={PageConstant.COLUMNS}
            className={
              "flex items-center justify-center bg-blue btn-primary-round h-75px text-white text-xl"
            }
            style={{ width: 400 }}
          >
            <span className={"mr-8"}>お知らせ一覧へ</span>
            <img
              alt=""
              src={require("../../assets/images/circle_arrow_yellow.svg")}
            />
          </Link>
        </div>
      </div>
    </Fragment>
  );
}

export default injectIntl(ColumnDetail)
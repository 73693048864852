import React, { Fragment } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuthState } from "../../context";
import { PageConstant } from "../../constants/PageConstant";
import { isLogined, isShared } from "../../helper/utils";
import { HeaderMenu } from "./HeaderMenu";
import { Dropdown, Menu } from "antd";
import { T_Admin } from "../../constants/Permission";

export const Header = (props) => {
  let history = useHistory();
  const location = useLocation();
  const { profile } = useAuthState();

  const doLogin = (e) => {
    e.preventDefault();
    history.push(PageConstant.LOGIN);
  };

  const doSignup = (e) => {
    e.preventDefault();
    history.push(PageConstant.SIGNUP);
  };

  const doLogout = (e) => {
    e.preventDefault();
    history.push("/logout");
  };

  const doInquiryForm = (e) => {
    e.preventDefault();
    history.push(PageConstant.INQUIRY_FORM);
  };

  const profileMenu = (
    <Menu>
      <Menu.Item key="1" className={"co-menu-item"}>
        <Link to={PageConstant.TEACHER_PROFILE}>
          <span className={"font-bold flex justify-center"}>教員情報変更</span>
        </Link>
      </Menu.Item>
      {profile.permission === T_Admin && (
        <Menu.Item key="2" className={"co-menu-item"}>
          <Link to={PageConstant.TEACHER_MANAGE}>
            <span className={"font-bold flex justify-center"}>教員管理</span>
          </Link>
        </Menu.Item>
      )}
    </Menu>
  );

  const shared = isShared();
  const locationState = useLocation().state;
  console.log(profile);
  return (
    <Fragment>
      <div className={"header"}>
        <div className={"header-top flex items-center px-3 md:px-32 py-4"}>
          {!(!isLogined(profile) && location.pathname === PageConstant.HOME) && (
            <Link to={PageConstant.HOME} className={"mr-3 max-w-16"}>
              <img alt="" src={require("../../assets/images/header_logo.svg")} />
            </Link>
          )}
          {shared && (
            <Fragment>
              <div className="px-8 font-bold text-lg flex items-center justify-center w-full">
                {locationState && <span>{locationState.programTitle}</span>}
                <span></span>
              </div>
            </Fragment>
          )}
          {!isLogined(profile) && !shared && (
            <Fragment>
              <div className="flex-grow text-right flex flex-row justify-end gap-3 md:gap-0">
                <button
                  className="bg-blue-border text-blue-900 font-semibold text-xs md:text-base py-2 px-2 md:px-4 rounded-lg md:mx-3 w-24 md:w-150px"
                  onClick={doLogin}
                >
                  <span>ログイン</span>
                </button>
                <button
                  className="bg-blue hover:bg-blue-900 text-white text-xs md:text-base font-semibold py-2 px-2 md:px-4 rounded-lg md:mx-3 w-24 md:w-150px"
                  onClick={doSignup}
                >
                  <span>新規会員登録</span>
                </button>
              </div>
            </Fragment>
          )}
          {isLogined(profile) && !shared && (
            <Fragment>
              <div className="flex-grow text-right">
                <Dropdown overlay={profileMenu}>
                  <button
                    type="button"
                    id="dropdownButton"
                    data-dropdown-toggle="dropdown"
                    className="bg-blue hover:bg-blue-900 text-white font-semibold inline-flex py-2 pl-2 md:px-4 rounded-lg mx-1 md:mx-3 w-32 md:w-171px"
                  >
                    <div>
                      <img
                        alt=""
                        src={require("../../assets/images/header_profile.svg")}
                      />
                    </div>
                    <span className="pl-2">{profile.name.substring(0, 5)}様</span>
                  </button>
                </Dropdown>
              </div>
              <div>
                <button
                  className="text-blue-900 font-semibold inline-flex py-2 px-0 md:px-4 rounded-lg mx-auto md:mx-3 w-20 md:w-150px justify-end md:justify-start"
                  onClick={doLogout}
                >
                  <span>ログアウト</span>
                </button>
              </div>
            </Fragment>
          )}
          {!shared && (
            <div>
              <button
                className="ml-3 md:ml-4 align-middle"
                onClick={doInquiryForm}
              >
                <div className={"flex justify-center"}>
                  <img
                    alt=""
                    src={require("../../assets/images/header_email.svg")}
                    className={"min-w-39px"}
                  />
                </div>
                <span className="text-blue-900 font-semibold hidden md:block">
                  お問い合わせ
                </span>
              </button>
            </div>
          )}
        </div>
        {isLogined(profile) && !shared && <HeaderMenu />}
      </div>
    </Fragment>
  );
};

import React, {Fragment} from 'react';
import styled from "styled-components";

const TagContainer = styled.div`
  z-index: 1;
  background-color: #1E425F;
  -webkit-border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  display: inline-block;
  padding-right: 0.5rem;
  padding-top: 0.125rem;
`

const EmptyContainer = styled.div`

`

export const Tag = (props) => {
  return (
    <Fragment>
      <div className={"flex items-center"}>
        {props.item && (
          <Fragment>
            <img alt="" src={require("../../assets/images/bg_tag.png")} />
            <TagContainer className={"h-28px md:h-27px"}>
              <span className={"text-white whitespace-no-wrap"}>
                {props.item}
              </span>
            </TagContainer>
          </Fragment>
        )}
        {!props.item && (
          <Fragment>
            <EmptyContainer className={"h-28px md:h-27px"} />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

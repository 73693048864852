import React, { Fragment } from "react";
import { ColumnBox } from "./ColumnBox";
import { PageConstant } from "../../constants/PageConstant";
import { useHistory } from "react-router-dom";

export const ColumnItem = (props) => {
  let history = useHistory();
  const onItemClick = (e, item) => {
    e.preventDefault();
    history.push(PageConstant.COLUMN_DETAIL, { data: item });
  };

  return (
    <Fragment>
      <div onClick={(e) => onItemClick(e, props.item)}>
        <div
          className={"bg-white"}
          style={{ borderRadius: 10, overflow: "hidden" }}
        >
          <div
            className={
              "center-cropped-wrapper w-full h-columnitem md:h-columnitem"
            }
          >
            <img
              alt=""
              className={"center-cropped w-full object-cover md:object-fill"}
              src={
                props.item.notification_image_url !== ""
                  ? props.item.notification_image_url
                  : require("../../assets/images/column_thumbnail.png")
              }
            />
          </div>
          <div className={"pb-4 px-4 mt-4"}>
            <ColumnBox title={props.item.public_start_date} />
            <div className={"my-2"}>
              <span className={"text-sm md:text-lg"}>{props.item.title}</span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

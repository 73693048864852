import React, {Fragment} from 'react';
import styled from "styled-components";
import {StepTitleItem} from "./StepTitleItem";

const Container = styled.div`
  border: 2px solid #EABD00;
  padding: 1rem 2rem;
`

export const StepTitle = (props) => {
  return (
    <Fragment>
      <Container>
        {
          props.titles.map((item, key) => (
            <StepTitleItem key={key} title={item}/>
          ))
        }
      </Container>
    </Fragment>
  )
}
